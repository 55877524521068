const predefinedNearbyPlaces = [
  { name: "ParkingHub", lat: 28.542094, lng: 77.184638 },
  { name: "IIT Delhi ParkingHub", lat: 28.545292, lng: 77.185596 },
  { name: "Block E", lat: 28.54288, lng: 77.188278 },
  { name: "Aryabhatta Rd", lat: 28.543329, lng: 77.17557 },
  { name: "Karakoram Hostel", lat: 28.547872, lng: 77.182594 },
  { name: "Badarpur Metro Station", lat: 28.500761, lng: 77.302343 },
  { name: "Tughlaqabad Fort", lat: 28.515319, lng: 77.284811 },
  { name: "NTPC Badarpur", lat: 28.49493, lng: 77.30304 },
  { name: "Badarpur Border", lat: 28.50111, lng: 77.30283 },
  { name: "Badarpur Thermal Power Station", lat: 28.49483, lng: 77.30405 },
  { name: "Sangam Vihar Main Market", lat: 28.508174, lng: 77.251129 },
  { name: "Batra Hospital", lat: 28.505947, lng: 77.252485 },
  { name: "Deoli Gaon", lat: 28.51147, lng: 77.247933 },
  { name: "B Block Sangam Vihar", lat: 28.509432, lng: 77.25093 },
  { name: "H Block Sangam Vihar", lat: 28.510517, lng: 77.248745 },
  { name: "RK Puram Sector 1", lat: 28.568716, lng: 77.180989 },
  { name: "RK Puram Sector 2", lat: 28.566894, lng: 77.178488 },
  { name: "RK Puram Sector 6", lat: 28.563515, lng: 77.173383 },
  { name: "RK Puram Sector 8", lat: 28.562171, lng: 77.170871 },
  { name: "Moti Bagh", lat: 28.576768, lng: 77.165761 },
  { name: "Nehru Place", lat: 28.549444, lng: 77.250556 },
  { name: "Kalkaji Mandir", lat: 28.54932, lng: 77.25947 },
  { name: "Hauz Khas Village", lat: 28.553992, lng: 77.193516 },
  { name: "Green Park", lat: 28.563668, lng: 77.205817 },
  { name: "AIIMS Delhi", lat: 28.567237, lng: 77.210884 },
];

export default predefinedNearbyPlaces;
